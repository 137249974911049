.ck-editor__editable {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
}

.ck-toolbar {
  background-color: #e9ecef;
  border: 1px solid #d9d9d9;
}

.ck-editor__editable_inline {
  border: 1px solid #d9d9d9;
}

.ck-editor__editable[contenteditable="false"] {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.04);
}
